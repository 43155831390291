import React, {FC, useState} from 'react';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';

import {TypoComponentsStarcarAusklappbarerText} from 'lib/api/strapi';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {Grid, Icon, Title} from '../../basic-components';
import Markdown from '../../basic-components/MarkdownContent';
import DefaultWrapper from '../../layout/DefaultWrapper/DefaultWrapper';
import styles from './foldoutText.module.scss';

const StarcarAusklappbarerText: FC<TypoComponentsStarcarAusklappbarerText> = ({
  invisibleText,
  visibleText,
  headline,
  headerLevel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DefaultWrapper bgcolor="light-grey" variant="paddingAround">
      <Grid container direction="row" justifyContent="center" sx={{width: '100%', margin: 0}}>
        <Grid xs={12} sm={9} textAlign="center">
          <Grid container direction="column" alignItems="center">
            <Title className={styles.centerText} variant={headerLevel}>
              {headline}
            </Title>
            <Markdown className={`${styles.centerText} ${styles.marginY}`} content={visibleText} />
            <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
              <Markdown
                className={`${styles.centerText} ${styles.invisibleText}`}
                content={invisibleText}
              />
            </AnimateHeight>
            <Button
              className={styles.button}
              variant="toggle"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <Icon baseClassName="fa-solid" className={isOpen ? 'fa-minus' : 'fa-plus'} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DefaultWrapper>
  );
};

export default withErrorBoundary(StarcarAusklappbarerText, 'StarcarAusklappbarerText');
